
function valid(input: any): input is number {
  return input != null && typeof input === "number" && !isNaN(input) && isFinite(input);
}

function clamp(v: number, min: number, max: number): number;
function clamp(v: number, interval: NumInterval): number;
function clamp(v: number, min: number | NumInterval, max?: number): number {
  if (typeof min === 'object') ({ min, max } = min); 
  if (!num.valid(max)) max = Number.MAX_VALUE;
  if (!num.valid(min)) min = Number.MIN_VALUE;
  if (max < min) [min, max] = [max, min];
  return Math.min(max, Math.max(min, v));
}

function round<T extends number | undefined>(value: T, interval: number): T;
function round(value: number, interval: number) {
  return Math.round(value / interval) * interval;
}
function ceil<T extends number | undefined>(value: T, interval: number): T;
function ceil(value: number, interval: number) {
  return Math.ceil(value / interval) * interval;
}
function floor<T extends number | undefined>(value: T, interval: number): T;
function floor(value: number, interval: number) {
  return Math.floor(value / interval) * interval;
}

export const num = {
  valid,
  validOr<T>(v: number | undefined, fallback: T) : number | undefined | T {
    return valid(v) ? v : fallback;
  },
  clamp,
  round,
  ceil,
  floor,
  // modulo fixed for negative values
  mod(n:number, m:number) {
    return ((n % m) + m) % m;
  }
};
