import React, { FC, memo, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
//
import config from "config/config";
import logo from "image/favicon_blue.png";
import { getUserTheme } from "ui/theme/theme.preload";
import { isError, isUnauthorizedError } from "utils/error";
//
import type { FallbackProps } from "react-error-boundary";
import { LoginFrame } from "./LoginFrame";


// Hard overlays over the entire application, for initial loading and errors
// Meaning the application is not yet in a state to be displayed at all.
// !Note!: Keep dependencies minimal, so they can be used early on while loading other components

// No UI/AntD/Data/Store or any other heavy external depenendancies


export const removeStaticOverlay = () => {
  try { document.getElementById("static-app-overlay")?.remove(); } catch { }
}

export const LoadingOverlay: FCC<{ message?: string; theme?: "dark" | "light";  }> = memo(({ message = "Loading..", theme }) => {
  return (
    <FullOverlay type="loading" theme={theme}>
      <div>{message}</div>
    </FullOverlay>
  );
});

export const ErrorOverlay: FCC<FallbackProps> = memo(({ error, resetErrorBoundary }) => {
  if (isUnauthorizedError(error)) {
    return <LoginFrame reset={resetErrorBoundary} />
  }
  return (
    <FullOverlay type="error">
      <h3>An error occurred</h3>
        <div className="error-content">
          {getErrorContent(error)}
        </div>
    </FullOverlay>
  );
});



type FullOverlayProps = {
  type: "error" | "loading" | "login",
  theme?: "light" | "dark";
  hideHeader?: true,

}

export const FullOverlay: FCC<FullOverlayProps> = memo(({ children, type, theme, hideHeader }) => {

  const containerRef = useRef(document.getElementById(OVERLAY_ID));
  if (!containerRef.current) {
    containerRef.current = document.createElement("div");
    containerRef.current.setAttribute("id", OVERLAY_ID);
    document.body.appendChild(containerRef.current);
  }

  // If we are showing this at any point, we can remove the static version embedded in the html
  useEffect(removeStaticOverlay, []);

  theme = getUserTheme(theme);

  return ReactDOM.createPortal(
    <div style={theme === "dark" ? dark : light} className={`full-app-overlay ${type}-overlay`} >
      <div>
        { !hideHeader && <div><img src={logo} width="64" height="64" /></div>}
        {children}
      </div>
    </div>,
    containerRef.current
  );
});


function getErrorContent(e?: Error | unknown, d = 0) {
  if (d > 0 && !config.isDev) return;
  if (e == null || d > 3) return false;
  if (!isError(e)) return <pre>{e.toString()}</pre>
  //if (!e.cause) console.trace(e);
  return <>
    <div style={{ marginLeft: d * 10 }}>
      <strong><code>{e?.name ?? e.toString()}</code></strong>
      {e?.message ? <pre>{e.message}</pre> : false}
      {getErrorContent(e?.cause, d + 1)}
    </div>
    {!e.cause && config.isDev && (
      <div>
        <h5>Stack Trace</h5>
        <pre style={{ maxHeight: 250, overflow: "auto", marginLeft: -50 }}>
          {e.stack}
        </pre>
      </div>
    )}
  </>;
}


const OVERLAY_ID = "custom-component-overlay";
const light: React.CSSProperties = {
  background: "#FFF",
  color: "#333"
};
const dark: React.CSSProperties = {
  background: "#333",
  color: "#CCC"
};