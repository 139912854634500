import type { GetKeys } from "../types";

export function map2<T extends Readonly<object>, O>(input: T, f: <P extends GetKeys<T>>(o: T[P], k: P) => O) {
  const output: { [key: string]: O } = {};
  forEach(input, (k, v) => output[k] = f(v, k));
  return output as { [key in keyof typeof input]: O };
}

type MapFn<T, P extends GetKeys<T>, O> = (v: T[P], k: P) => O | undefined
export function map<T extends Readonly<object>, O, K extends GetKeys<T>>(input: T, f: MapFn<T, K, O>) {
  const output: any = {};
  for (const k in input) {
    const v = input[k as any as K];
    const o = f(v, k as unknown as K);
    output[k] = o;
  }
  return output as { readonly [key in K]: NonNullable<O> };
}

export function toArray<Obj extends Readonly<object>, Out>(input: Obj, f: (v: Obj[keyof Obj], k: keyof Obj, o: Obj) => Out) {
  return keys(input).map(k => f(input[k], k, input))
}

export function toArray2<K, V, O>(input: any , f: (v: V, k: K, a?: any) => O = (v => v as any)): O[] {
  return Object.keys(input).map(key => f(input[key], key as any as K));
}

//export function fromValuesArray<K, V>(array: V[], tranformer: (v: T) => { k: T, v: O }) {

//}
export function fromKeysArray<K extends string, V>(array: K[], tranformer: (v: K) => V) {
  const res = {} as { [key in K]: V };
  array.forEach(k => res[k] = tranformer(k));
  return res;
}

export function notNull<T extends object>(input: T) {
  const output: AnyRec = {};
  forEach(input, (k, v) => v != null && (output[k] = v));
  return output as Required<T>;
}

export function forEach<T extends object>(o: T, f: (k: GetKeys<T>, v: T[GetKeys<T>]) => void) {
  keys(o).forEach(k => f(k, o[k]));
}

export function keys<T extends {}>(o: T) {
  return Object.keys(o) as GetKeys<T>[];
}

export function deepFreeze<T extends {} = {}>(obj: T) {
  Object.keys(obj).forEach((prop) => {
    const value = (obj as any)[prop];
    if (typeof value === "object" && !Object.isFrozen(value)) {
      deepFreeze(value);
    }
  });
  return Object.freeze(obj);
}

export function isObject(val: any): val is Record<string, any> {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

/** typing doesnt work, but deletes properties from object that are undefined or null */
export function stripUndefined<T extends Record<string, any>>(obj: T): T {
  Object.keys(obj).forEach(k => obj[k] == null && delete obj[k]);
  return obj;
}


type Opt = AnyRec | null | undefined;
export function keysEqual<A extends Opt, B extends Opt>(a: A, b: B, keys: (keyof A & keyof B)[]) {
  return a ? b ? keys.every(k => a[k] === b[k]) : false : true;
}

export function shallowEqual(a: any, b: any) {
  if (a === b) return true;
  if (!(a instanceof Object) || !(b instanceof Object)) return false;

  var keys = Object.keys(a);
  var length = keys.length;

  for (var i = 0; i < length; i++)
    if (!(keys[i] in b)) return false;

  for (var i = 0; i < length; i++)
    if (a[keys[i]] !== b[keys[i]]) return false;

  return length === Object.keys(b).length;
}


export const objectId = (function () {
  let idCounter = 1;
  return function objectId(o: any): number {
    if (o.__uniqueid === undefined) {
      Object.defineProperty(o, "__uniqueid", {
        value: ++idCounter,
        enumerable: false,
        writable: false
      });
    }
    return o.__uniqueid;
  };
})();


export default {
  map,
  map2,
  forEach,
  keys,
  toArray,
  toArray2,
  fromKeysArray,
  deepFreeze,
  isObject,
  objectId,
  stripUndefined,
  shallowEqual,
  keysEqual
};
