import { z } from "zod";

// spec describing native RequestInit type.
export const IRequestInit: z.ZodType<Partial<RequestInit>> = z
  .object({
    body: z.string().nullable(),
    cache: z.union([
      z.literal("default"),
      z.literal("force-cache"),
      z.literal("no-cache"),
      z.literal("no-store"),
      z.literal("only-if-cached"),
      z.literal("reload"),
    ]) as z.ZodType<RequestCache>,
    credentials: z.union([
      z.literal("include"),
      z.literal("omit"),
      z.literal("same-origin"),
    ]) as z.ZodType<RequestCredentials>,
    headers: z.union([
      z.array(z.tuple([z.string(), z.string()])),
      z.record(z.string()),
    ]) as z.ZodType<HeadersInit>,
    integrity: z.string(),
    keepalive: z.boolean(),
    method: z.string(),
    mode: z.union([
      z.literal("cors"),
      z.literal("navigate"),
      z.literal("no-cors"),
      z.literal("same-origin"),
    ]) as z.ZodType<RequestMode>,
    redirect: z.union([
      z.literal("error"),
      z.literal("follow"),
      z.literal("manual"),
    ]) as z.ZodType<RequestRedirect>,
    referrer: z.string(),
    referrerPolicy: z.union([
      z.literal(""),
      z.literal("no-referrer"),
      z.literal("no-referrer-when-downgrade"),
      z.literal("origin"),
      z.literal("origin-when-cross-origin"),
      z.literal("same-origin"),
      z.literal("strict-origin"),
      z.literal("strict-origin-when-cross-origin"),
      z.literal("unsafe-url"),
    ]) as z.ZodType<ReferrerPolicy>,
    window: z.null(),
  })
  .partial()
  .strip()
  .or(z.object({}));

export const ZodLiteralRecord = <
  KeyType extends string,
  ZodValueType extends z.ZodTypeAny
>(
  keys: KeyType[],
  zodValueType: ZodValueType
) =>
  z.object(
    keys.reduce(
      (agg, k) => ({
        ...agg,
        [k]: zodValueType.optional(),
      }),
      {} as Record<KeyType, z.ZodUnion<[ZodValueType, z.ZodUndefined]>>
    )
  );
