import { isArray } from "mathjs";
import { autorun, intercept, observe, reaction, when } from "mobx";
import { IDisposer, NOOP } from "mobx-utils";
import { obj } from "./data";
import { logger } from "./logger";
import { setGlobal } from "./storage/global";

// Utility mixin/base class to automate some common cleanup, usually reaction/event handlers

// -------------------------------------------------------------------------------------------------
// Mostly the rest is here to get a cleaner type definition out of the result,
// and get the methods on the prototype and not the instance
// Look like a a complicated mess, but works well.

const methods = obj.map(
  { autorun, reaction, observe, intercept, when },
  // eslint-disable-next-line @typescript-eslint/ban-types
  function (fn: Function) {
    return function (this: any, ...args: any[]) {
      return this.addDisposer(fn(...args));
    };
  }
) as any;

interface DomAddListener {
  <O extends Window, K extends keyof GlobalEventHandlersEventMap>(
    target: O,
    type: K,
    listener: (
      this: GlobalEventHandlers,
      ev: GlobalEventHandlersEventMap[K]
    ) => any,
    options?: boolean | AddEventListenerOptions
  ): () => void;
  <O extends Window, K extends keyof WindowEventHandlersEventMap>(
    target: O,
    type: K,
    listener: (
      this: WindowEventHandlers,
      ev: WindowEventHandlersEventMap[K]
    ) => any,
    options?: boolean | AddEventListenerOptions
  ): () => void;
  <O extends EventSource, K extends keyof EventSourceEventMap>(
    target: O,
    type: K,
    listener: (this: EventSource, ev: EventSourceEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): () => void;
}
methods.addEvent = function (
  this: any,
  el: EventTarget,
  event: any,
  listener: any,
  options?: any
) {
  el.addEventListener(event, listener, options);
  return this.addDisposer(() =>
    el.removeEventListener(event, listener, options)
  );
} as DomAddListener;

export const addEvent: DomAddListener = (el: any, t: any, l: any, o: any) => {
  el.addEventListener(t, l, o);
  return el.removeEventListener.bind(el, t, l, o);
};

function addDisposerProtos(proto: any) {
  for (const name in methods) {
    Object.defineProperty(proto, name, {
      enumerable: false,
      value: methods[name],
      writable: false,
      configurable: false,
    });
  }
}

// Since there is no good way to copy over the function typings without making them instance properties.
// this is a bit of a hack to provide them as class prototype methods
function wrapInternals<T extends Constructor>(Disposable: T) {
  addDisposerProtos(Disposable.prototype);
  // This class is not actually ever instantiated, just there to provide internal method definitions
  class DisposableProtected extends Disposable {
    protected readonly autorun = autorun;
    protected readonly reaction = ((a: any, b: any, c: any) =>
      reaction(a, b, {
        fireImmediately: true,
        ...(c || {}),
      })) as typeof reaction;
    protected readonly observe = observe;
    protected readonly intercept = intercept;
    protected readonly when = when;
    protected readonly addEvent: DomAddListener = 0 as any;
  }
  return Disposable as unknown as typeof DisposableProtected;
}

export const Disposable = MakeDisposable(class Object {});
export type Disposable = typeof Disposable;
export type Constructor<T = Empty> = new (...args: any[]) => T;
class Empty {}

export function MakeDisposable<T extends Constructor>(SuperClass: T) {
  const log = logger("mem", false);
  let _instanceId = 0;

  return wrapInternals(
    class Disposable extends SuperClass {
      constructor(...args: any[]) {
        super(...args);
        Object.defineProperty(this, dispId, {
          value: this.constructor.name + ":" + ++_instanceId,
          enumerable: false,
          writable: false,
        });
        registry.created(this[dispId]);
        log.log(
          `Create => ${this.constructor.name} => Disposable => ${SuperClass.name}`
        );
      }

      private [dispId]!: string;
      private _timeoutIds = new Set<number>();
      private _intervalIds = new Set<number>();
      private _disposers = new Set<IDisposer>();
      private _disposed = false;

      protected setTimeout(fn: () => void, ms: number) {
        const id = window.setTimeout(fn, ms);
        this._timeoutIds.add(id);
        return id;
      }
      protected clearTimeout(id: number) {
        clearTimeout(id);
        this._timeoutIds.delete(id);
      }
      protected setInterval(fn: () => void, ms: number) {
        const id = window.setInterval(fn, ms);
        this._intervalIds.add(id);
        return id;
      }
      protected clearInterval(id: number) {
        clearInterval(id);
        this._intervalIds.delete(id);
      }

      /** Just a sneaky setter wrapping "addDisposer(s)", so there no need to wrap the statement */
      protected set _disp_(disposer: IDisposer | IDisposer[]) {
        if (isArray(disposer)) {
          this.addDisposers(...disposer);
        } else {
          this.addDisposer(disposer);
        }
      }

      protected addDisposer<T extends IDisposer>(disposer: T): T;
      protected addDisposer(disposer: IDisposer) {
        this._disposers.add(disposer);
        return () => {
          this._disposers.delete(disposer);
          disposer();
        };
      }

      protected addDisposers(...disposers: IDisposer[]): IDisposer[] {
        return disposers.map((disp) => this.addDisposer(disp));
      }

      // Lazy creation with auto cleanup
      // @ts-expect-error fake return value to trick typescript
      protected lazy<T>(prop: keyof this, create: () => T): T {
        Object.defineProperty(this, prop, {
          get: () => {
            const value = create();
            Object.defineProperty(this, prop, {
              value,
              writable: false,
              configurable: true,
            });
            this.addDisposer(() => {
              Object.defineProperty(this, prop, {
                value: undefined,
                writable: false,
                configurable: false,
              });
            });
            return value;
          },
          set: NOOP,
          enumerable: true,
          configurable: true,
        });
      }

      public get disposed() {
        return this._disposed;
      }

      private set disposed(disposed: boolean) {
        this._disposed = disposed;
      }

      dispose() {
        if (!this.disposed) {
          registry.disposed(this, this[dispId]);

          log.log(
            " ".repeat(depth * 4),
            "Dispose => ",
            this.constructor.name,
            ""
          );
          depth++;
          this.disposed = true;
          this.disposeInternal();
        } else {
          //console.warn("already disposed", this.constructor.name);
          return;
        }
      }

      protected disposeInternal() {
        try {
          //@ts-expect-error call subclass that has it too (ol.Disposable))
          super.disposeInternal?.();
        } catch {}

        this._disposers.forEach((d) => {
          try {
            d?.();
          } catch {}
        });
        this._timeoutIds.forEach((id) => clearTimeout(id));
        this._intervalIds.forEach((id) => clearInterval(id));

        this._disposers.clear();
        this._timeoutIds.clear();
        this._intervalIds.clear();

        depth--;
        //console.log(" ".repeat(depth * 4), "}");
      }
    }
  );
}
let depth = 0;

// -------------------------------------------------------------------------------------
// Track garbage disposals

class TrackingRegistry extends FinalizationRegistry<string> {
  constructor() {
    super((value) => {
      this.disposedItems.delete(value);
      //console.log(value, "was garbage collected", this.disposedItems);
    });
  }
  disposed(target: any, name: string) {
    this.disposedables.delete(name);
    this.disposedItems.add(name);
    //console.log("disposing:" + name);
    this.register(target, name);
  }
  created(name: string) {
    this.disposedables.add(name);
  }
  public readonly disposedItems = new Set<string>(); // observable.set<string>();
  public readonly disposedables = new Set<string>(); // observable.set<string>();
}
const dispId = Symbol("disposable id");
export const registry = new TrackingRegistry();
setGlobal("memRegistry", registry);
