
export const map = {

  getOrCreate<K, V>(map: Map<K, V>, key: K, constructor: () => V) {
    let v = map.get(key);
    if (v !== undefined) return v;
    v = constructor();
    map.set(key, v);
    return v;
  }

};