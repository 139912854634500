//import type Console from 'typescript/lib/lib.dom';
import { config } from "config/config";

export function logger(category = "default", enabled?: boolean) {
  const fn = function log(...args: any[]) {
    if (!isEnabled("log")) return;
    return console.log(...args);
  } as any;

  function isEnabled(type: keyof Console) {
    if (enabled === false) return false;
    if (enabled === true) return true;
    if (config.log.types[category] === true) return true;   // whitelist
    if (config.log.types[category] === false) return false; // blacklist
    if (!config.log.enabled !== false) return false;        // force disable
    return logLevels[type] >= logLevels[config.log.level];  // by log level
  }
  Object.getOwnPropertyNames(console).forEach(method => {
    const type = method as keyof Console;
    if (isEnabled(type) && enabled) {
      fn[type] = console[type];
    } else {
      fn[type] = (() => { }) as any;
    }
  });

  return fn as Console & Console["log"];
}

const logLevels = {
  "debug": 1,
  "info": 2,
  "log": 3,
  "warn": 4,
  "assert": 5,
  "error": 6,
} as any;

const perfLog = logger("perf");
export function perf(name: string) {
  const ts = performance.now();
  return () => {
    const tt = performance.now() - ts;
    perfLog.log(name + ": " + Math.round(tt) + "ms");
  };
}

export const log = logger();
export const memlog = logger("mem");
