import { action } from 'mobx';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';



// React 18 prep
// See https://blog.ag-grid.com/avoiding-react-18-double-mount/
export const useEffectOnce = (effect: () => void | (() => void)) => {

  const effectFn = useRef<() => void | (() => void)>(effect);
  const destroyFn = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const rendered = useRef(false);
  const [, setVal] = useState<number>(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!rendered.current) { return; }

      // otherwise this is not a dummy destroy, so call the destroy func
      if (destroyFn.current) { destroyFn.current(); }
    };
  }, []);
};




type Fn<A extends any[], R> = (...args: A) => R;

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
// https://github.com/Volune/use-event-callback/blob/master/src/index.ts
export const useEvent = <A extends any[], R>(fn: Fn<A, R>): Fn<A, R> => {
  const ref = useRef<Fn<A, R>>(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useMemo(() => (...args: A): R => {
    const { current } = ref;
    return current(...args);
  }, []);
};

// Similar to useEvent, but uses a mobx action and an optional delay
export function useAction<A extends any[], R>(fn: Fn<A, R>): Fn<A, R>;
export function useAction<A extends any[]>(fn: Fn<A, void>, delay: number): Fn<A, void>;
export function useAction<A extends any[], R>(fn: Fn<A, R>, delay?: number): Fn<A, R> {
  const ref = useRef<Fn<A, R>>(fn);
  let cancelDelay: Fn<[], void> | undefined = undefined;
  useLayoutEffect(() => {
    ref.current = fn;
    return () => cancelDelay?.();
  });
  return useMemo(() => {
    const handler = action((...args: A) => ref.current(...args));
    if (delay === 0) {
      return (...args: A) => {
        const frameId = requestAnimationFrame(() => handler(...args));
        cancelDelay = () => cancelAnimationFrame(frameId);
      };
    }
    if (delay) {
      return (...args: A) => {
        const timeoutId = window.setTimeout(() => handler(...args), delay);
        cancelDelay = () => window.clearTimeout(timeoutId);
      };
    }
    return handler;
  }, []) as Fn<A, R>;
};

export const delay = <A extends any[]>(fn: Fn<A, void>) => {
  return useAction(fn, 0);
}