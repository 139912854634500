import config from "config/config";
import { useEffectOnce } from "ui/utils/hooks";
import { FullOverlay } from "./FullOverlays";
import type { FC } from "react";
import { addEvent } from "utils/Disposable";



export const LoginFrame: FC<{ reset: Fn; }> = ({ reset }) => {

  useEffectOnce(() => {
    const unListen = addEvent(window, "storage", e => {
      if (e.key === "login") {
        unListen();
        reset();
      }
    });
    return unListen;
  });

  return (
    <FullOverlay hideHeader type="login" >
      <iframe
        referrerPolicy="same-origin"
        src={config.loginUri}
        width="450"
        height="290"
        scrolling="no"
        frameBorder="none"
        seamless
        style={{ overflow: "hidden", border: "none" }}></iframe>
    </FullOverlay>
  );
};