import Polygon from 'ol/geom/Polygon';
import type { Coordinate } from 'ol/coordinate';
import type { Extent } from 'ol/extent';
//
import { coordinateEqual, degToRad as toRad, earthRadius, radToDeg as toDeg, toLonlat, toMercator } from './geo';
import { Vector3d } from './Vector3d';

export function distance(point1: Coordinate, point2: Coordinate, mercator = false): number {

  if (mercator) {
    point1 = toLonlat(point1);
    point2 = toLonlat(point2);
  }

  const φ1 = toRad(point1[1]), λ1 = toRad(point1[0]);
  const φ2 = toRad(point2[1]), λ2 = toRad(point2[0]);
  const sinΔφ2 = Math.sin((φ2 - φ1) / 2);
  const sinΔλ2 = Math.sin((λ2 - λ1) / 2);

  const a = sinΔφ2 * sinΔφ2 + Math.cos(φ1) * Math.cos(φ2) * sinΔλ2 * sinΔλ2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
}

export function bearing(point1: Coordinate, point2: Coordinate, mercator = false): number {

  if (mercator) {
    point1 = toLonlat(point1);
    point2 = toLonlat(point2);
  }

  const φ1 = toRad(point1[1]), φ2 = toRad(point2[1]);
  const Δλ = toRad(point2[0] - point1[0]);
  const cosφ2 = Math.cos(φ2);

  const y = Math.sin(Δλ) * cosφ2;
  const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * cosφ2 * Math.cos(Δλ);
  const θ = Math.atan2(y, x);

  return (toDeg(θ) + 360) % 360;
}

export function destinationPoint(coord: Coordinate, bearing: number, dist: number, mercator = false): Coordinate {

  if (mercator) {
    coord = toLonlat(coord);
  }

  const δ = dist / earthRadius;
  const θ = toRad(bearing);

  const φ1 = toRad(coord[1]);
  const λ1 = toRad(coord[0]);

  const sinφ1 = Math.sin(φ1), cosφ1 = Math.cos(φ1);
  const sinδ = Math.sin(δ), cosδ = Math.cos(δ);
  const sinθ = Math.sin(θ), cosθ = Math.cos(θ);

  const sinφ2 = sinφ1 * cosδ + cosφ1 * sinδ * cosθ;
  const φ2 = Math.asin(sinφ2);
  const y = sinθ * sinδ * cosφ1;
  const x = cosδ - sinφ1 * sinφ2;
  const λ2 = λ1 + Math.atan2(y, x);

  const dest = [(toDeg(λ2) + 540) % 360 - 180, toDeg(φ2)] as Coordinate;

  return mercator ? toMercator(dest) : dest;
}

export function midpoint(point1: Coordinate, point2: Coordinate, mercator = false): Coordinate {

  if (mercator) {
    point1 = toLonlat(point1);
    point2 = toLonlat(point2);
  }

  const φ1 = toRad(point1[1]), λ1 = toRad(point1[0]);
  const φ2 = toRad(point2[1]);
  const Δλ = toRad(point2[0] - point1[0]);

  const Bx = Math.cos(φ2) * Math.cos(Δλ);
  const By = Math.cos(φ2) * Math.sin(Δλ);

  const x = Math.sqrt((Math.cos(φ1) + Bx) * (Math.cos(φ1) + Bx) + By * By);
  const y = Math.sin(φ1) + Math.sin(φ2);
  const φ3 = Math.atan2(y, x);

  const λ3 = λ1 + Math.atan2(By, Math.cos(φ1) + Bx);

  const middle = [(toDeg(λ3) + 540) % 360 - 180, toDeg(φ3)] as Coordinate;

  return mercator ? toMercator(middle) : middle;

}

export function intermediatePointTo(point1: Coordinate, point2: Coordinate, fraction: number, mercator = false): Coordinate {

  if (coordinateEqual(point1, point2)) {
    return point1;
  }

  if (mercator) {
    point1 = toLonlat(point1);
    point2 = toLonlat(point2);
  }

  const φ1 = toRad(point1[1]), λ1 = toRad(point1[0]);
  const φ2 = toRad(point2[1]), λ2 = toRad(point2[0]);
  const sinφ1 = Math.sin(φ1), cosφ1 = Math.cos(φ1), sinλ1 = Math.sin(λ1), cosλ1 = Math.cos(λ1);
  const sinφ2 = Math.sin(φ2), cosφ2 = Math.cos(φ2), sinλ2 = Math.sin(λ2), cosλ2 = Math.cos(λ2);

  const Δφ = φ2 - φ1;
  const Δλ = λ2 - λ1;
  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
    + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const δ = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const A = Math.sin((1 - fraction) * δ) / Math.sin(δ);
  const B = Math.sin(fraction * δ) / Math.sin(δ);

  const x = A * cosφ1 * cosλ1 + B * cosφ2 * cosλ2;
  const y = A * cosφ1 * sinλ1 + B * cosφ2 * sinλ2;
  const z = A * sinφ1 + B * sinφ2;

  const φ3 = Math.atan2(z, Math.sqrt(x * x + y * y));
  const λ3 = Math.atan2(y, x);

  const inter = [(toDeg(λ3) + 540) % 360 - 180, toDeg(φ3)] as Coordinate;

  return mercator ? toMercator(inter) : inter;

}

//export function intersection(p1: Coordinate, brng1: number, p2: Coordinate, brng2: number): Coordinate {

//    const φ1 = toRad(p1[1]), λ1 = toRad(p1[0]);
//    const φ2 = toRad(p2[1]), λ2 = toRad(p2[0]);
//    const θ13 = toRad(brng1), θ23 = toRad(brng2);
//    const Δφ = φ2 - φ1, Δλ = λ2 - λ1;

//    const δ12 = 2 * Math.asin(Math.sqrt(Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
//        + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));
//    if (δ12 === 0) return null;

//    let θa = Math.acos((Math.sin(φ2) - Math.sin(φ1) * Math.cos(δ12)) / (Math.sin(δ12) * Math.cos(φ1)));
//    if (isNaN(θa)) θa = 0;
//    const θb = Math.acos((Math.sin(φ1) - Math.sin(φ2) * Math.cos(δ12)) / (Math.sin(δ12) * Math.cos(φ2)));

//    const θ12 = Math.sin(λ2 - λ1) > 0 ? θa : 2 * Math.PI - θa;
//    const θ21 = Math.sin(λ2 - λ1) > 0 ? 2 * Math.PI - θb : θb;

//    const α1 = (θ13 - θ12 + Math.PI) % (2 * Math.PI) - Math.PI;
//    const α2 = (θ21 - θ23 + Math.PI) % (2 * Math.PI) - Math.PI;

//    if (Math.sin(α1) === 0 && Math.sin(α2) === 0) return null;
//    if (Math.sin(α1) * Math.sin(α2) < 0) return null;

//    const α3 = Math.acos(-Math.cos(α1) * Math.cos(α2) + Math.sin(α1) * Math.sin(α2) * Math.cos(δ12));
//    const δ13 = Math.atan2(Math.sin(δ12) * Math.sin(α1) * Math.sin(α2), Math.cos(α2) + Math.cos(α1) * Math.cos(α3));
//    const φ3 = Math.asin(Math.sin(φ1) * Math.cos(δ13) + Math.cos(φ1) * Math.sin(δ13) * Math.cos(θ13));
//    const Δλ13 = Math.atan2(Math.sin(θ13) * Math.sin(δ13) * Math.cos(φ1), Math.cos(δ13) - Math.sin(φ1) * Math.sin(φ3));
//    const λ3 = λ1 + Δλ13;

//    return [(toDeg(λ3) + 540) % 360 - 180, toDeg(φ3)];
//}

export function intersection(p1start: Coordinate, p1end: Coordinate, p2start: Coordinate, p2end: Coordinate, mercator = false): Coordinate | null {


  if (mercator) {
    p1start = toLonlat(p1start);
    p1end = toLonlat(p1end);
    p2start = toLonlat(p2start);
    p2end = toLonlat(p2end);
  }

  const c1 = toVector(p1start).cross(toVector(p1end));
  const c2 = toVector(p2start).cross(toVector(p2end));
  const i1 = c1.cross(c2).toCoord();
  const i2 = c2.cross(c1).toCoord();
  if (isOnPath(i1, p1start, p1end) && isOnPath(i1, p2start, p2end)) {
    return mercator ? toMercator(i1) : i1;
  } else if (isOnPath(i2, p1start, p1end) && isOnPath(i2, p2start, p2end)) {
    return mercator ? toMercator(i2) : i2;
  }
  return null;
}

export function crossTrackDistanceTo(coord: Coordinate, pathStart: Coordinate, pathEnd: Coordinate, mercator = false) {

  const δ13 = distance(pathStart, coord, mercator) / earthRadius;
  const θ13 = toRad(bearing(pathStart, coord, mercator));
  const θ12 = toRad(bearing(pathStart, pathEnd, mercator));

  return Math.asin(Math.sin(δ13) * Math.sin(θ13 - θ12)) * earthRadius;
}

export function nearestPointOnSegment(coord: Coordinate, path1: Coordinate, path2: Coordinate, mercator = false) {

  const n0 = toVector(coord, mercator);
  const n1 = toVector(path1, mercator);
  const n2 = toVector(path2, mercator);
  const c1 = n1.cross(n2);
  const c2 = n0.cross(c1);
  const n = c1.cross(c2);
  const p = n.toCoord();

  if (isOnPath(p, path1, path2), mercator) return p;

  const d1 = distance(coord, path1, mercator);
  const d2 = distance(coord, path2, mercator);
  return d1 < d2 ? path1 : path2;
}

export function isOnPath(coord: Coordinate, path1: Coordinate, path2: Coordinate, mercator = false) {

  const pathBearing = bearing(path1, path2, mercator);
  const pointBearing = bearing(path1, coord, mercator);
  if (Math.abs(pathBearing - pointBearing) > 60) return false;

  const pathDistance = distance(path1, path2, mercator);
  const pointDisitance = distance(path1, coord, mercator);

  return pointDisitance <= pathDistance;
}

export function intersects(polygon: Polygon, coord: Coordinate) {
  return polygon.getLinearRings()
    .map(ring => new Polygon([ring.getCoordinates()]))
    .map(poly => poly.intersectsCoordinate(coord))
    .filter(v => v).length == 1;
}

//export function interpolate(coord1: Coordinate, coord2: Coordinate, fraction: number) {
//    const angle = bearing(coord1, coord2);
//    const dist = distance(coord1, coord2);
//    return destinationPoint(coord1, angle, dist * fraction);
//}

export function subdivide(coords: Coordinate[], subdivisions: number, mercator = false): Coordinate[] {
  const result = [];
  for (let i = 0; i < coords.length - 1; i++) {
    result.push(coords[i]);
    for (let j = 1; j < subdivisions; j++) {
      result.push(intermediatePointTo(coords[i], coords[i + 1], j / subdivisions, mercator));
    }
  }
  result.push(coords[coords.length - 1]);
  return result;
}

// Subdivide an distnace bearing line using GC
export function subdivideDB(coord: Coordinate, distance: number, bearing: number, subdivisions: number, mercator = false): Coordinate[] {
  const result = [coord];
  for (let j = 1; j < subdivisions; j++) {
    const fraction = j / subdivisions;
    result.push(destinationPoint(coord, bearing, distance * fraction, mercator));
  }
  return result;
}

function toVector(coord: Coordinate, mercator = false) {

  if (mercator) coord = toLonlat(coord);

  const φ = toRad(coord[1]);
  const λ = toRad(coord[0]);

  const x = Math.cos(φ) * Math.cos(λ);
  const y = Math.cos(φ) * Math.sin(λ);
  const z = Math.sin(φ);

  return new Vector3d(x, y, z);
}


export function extentSizeNM(e: Extent) {
  const ymid = (e[1] + e[3]) / 2;
  return {
    width: distance([e[0], ymid], [e[2], ymid]),
    height: distance([e[0], e[1]], [e[0], e[3]])
  }
}

export function bufferExtentNM(e: Extent, nm: number) {
  const ymid = (e[1] + e[3]) / 2;
  const xmid = (e[0] + e[2]) / 2;
  return [
    destinationPoint([e[0], ymid], 270, nm)[0],
    destinationPoint([xmid, e[1]], 180, nm)[1],
    destinationPoint([e[2], ymid], 90, nm)[0],
    destinationPoint([xmid, e[3]], 0, nm)[1],
  ] as Extent;
}