import { z } from "zod";
import { WebsuiteCalls } from "./api-calls";
import { IRequestInit, ZodLiteralRecord } from "./utils";

export interface IApiCall extends z.output<typeof IApiCall> {}
const IApiCall = z
  .object({
    call: z.string(),
    host: z.string().nonempty().optional(),
    path: z.string().nonempty().optional(),
    ext: z.string().nonempty().optional(),
    params: z.record(z.string(), z.any()).optional(),
    options: IRequestInit.optional(),
  })
  .strip();

const CallConfig = IApiCall.deepPartial();
export type IApiConfig = z.output<typeof IApiConfig>;
const IApiConfig = IApiCall.extend({
  call: z.never().optional(),
  host: z.string().url().optional(),
  overrides: z.record(z.string(), CallConfig).optional().default({}),
}).transform(({ overrides, ...defaults }) => ({ overrides, defaults }));

function StrictConfig<T extends string>(calls: readonly T[]) {
  const callRecord = ZodLiteralRecord(calls as T[], CallConfig);
  return IApiConfig.innerType()
    .omit({ overrides: true })
    .extend({
      overrides: callRecord.optional(),
    })
    .transform(
      ({ overrides = {}, ...defaults }: any) =>
        ({ overrides, defaults } as {
          overrides: z.output<typeof callRecord>;
          defaults: Omit<IApiConfig, "overrides">;
        })
    ) as unknown as typeof IApiConfig;
}

export type IApiConfigurations = z.output<typeof IApiConfigurations>;
const IApiConfigurations = z.object({
  aws: IApiConfig,
  dev: StrictConfig(WebsuiteCalls),
  live: IApiConfig,
  lmn: IApiConfig,
  static: IApiConfig,
});

export type IConfigFile = z.output<typeof IConfigFile>;
export const IConfigFile = z.object({
  loginUri: z.string(),
  logoutUri: z.string(),
  api: IApiConfigurations,
});
