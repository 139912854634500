import { glMatrix } from "gl-matrix";
glMatrix.setMatrixArrayType(Array);


// mobx config
import { configure } from "mobx";
configure({
  enforceActions: "never",
  disableErrorBoundaries: false,
  computedRequiresReaction: true,
  
  //observableRequiresReaction: true,
  //reactionRequiresObservable: true,
})

// react
import * as ReactDOMClient from 'react-dom/client';

//import reportWebVitals from './reportWebVitals';
import * as warning from "rc-util/es/warning";
import { ErrorBoundary } from "react-error-boundary";

// app
//import { AppContext } from "AppContext";
import { config } from 'config/config';
import { ErrorOverlay } from "ui/components/FullOverlays";
import { error, setGlobalErrorHandler } from "utils/error";
import { setGlobal } from "utils/storage/global";
//import { DevApp } from "ui/dev/DevApp";
import { AppContext } from "AppContext";
import React from "react";





// mount app 
async function initApplication() {
  const container = document.getElementById('root') ?? document.body;
  const root = ReactDOMClient.createRoot(container);
  try {

    // test function, unmounts app, for testing garbage collection/memory leaks
    setGlobal("unMount", () => root.render(<div/>));

    const setErrorRef = (boundary: ErrorBoundary | null) => {
      setGlobalErrorHandler(evt => {
        console.error(evt);
        requestAnimationFrame(() => boundary?.setState({ error: evt.error }));
        evt.preventDefault();
      });
    }
    await config.load();

    root.render(
      //<React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorOverlay} ref={setErrorRef} >
          <AppContext />
        </ErrorBoundary>
      //</React.StrictMode>
    );

  } catch (cause) {
    debugger;
    root.render(
      <ErrorOverlay
        error={error("Initialize", "", { cause })}
        resetErrorBoundary={document.location.reload}
      />
    );
  }

}

// dev stuff
const mod = module as any;
if (mod.hot) {
  //mod.hot.accept();
  mod.hot.addStatusHandler((_: any) => {
    //log(status, "------------------------"); 
    //console.clear();
  });
}
// disable warning
warning.call(() => null, false, "`disabled` should not set with empty `value`. You should set `allowEmpty` or `value` instead.")

// init
initApplication();
