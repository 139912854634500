import { useGlobal } from "./storage/global";


export class UnauthorizedError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "UnauthorizedError";
  }
}

// Convenience function for creating an error instance
export function error(type: string, message?: string, props?: { cause: any }) {
  const err = new Error(message, props);
  err.name = type + "Error";
  return err;
}


// Test if value is an error
export function isError(err: any): err is Error {
  return err instanceof Error;
}


// Test if the error was caused by Authorization issues.
export function isUnauthorizedError(err: any): boolean {
  if (err instanceof UnauthorizedError) return true;
  return !!err.cause && isUnauthorizedError(err.cause);
}


// Generates a reusable error handler to wrap other function calls in
export type ErrorHandler = ReturnType<typeof makeErrorHandler>;
export function makeErrorHandler(_catch: (e: unknown) => void) {
  return function _try<F extends CallableFunction>(fn: F): F {
    return (function (...args: any[]) {
      try {
        return fn(...args);
      } catch (e) {
        _catch(e);
      }
    }) as any as F;
  };
}


// Set the global error handler, this is for errors that have escaped all other boundaries and reached the root/window
export function setGlobalErrorHandler(listener: (e: ErrorEvent) => void, opts?: EventListenerOptions) {
  if (errHandler.listener) {
    window.removeEventListener("error", errHandler.listener, errHandler.opts);
  }
  window.addEventListener("error", listener, opts);
  errHandler.listener = listener;
  errHandler.opts = opts;
}

// we only want a single one, so store a global ref to it so we can remove any existing ones.
const errHandler: GlobalErrorHandler = useGlobal("gErrorHandler", () => ({}));
type GlobalErrorHandler = {
  listener?: (e: ErrorEvent) => void;
  opts?: EventListenerOptions;
}

