import type { vec2 } from "gl-matrix";
import arr from "../array";
import obj from "../object";
import { num } from "../number";
import { angle0To2PI, coordinateValid, degToRad, getAngle, getDistance, radToDeg } from "../geo";
//
import type { Coordinate } from "ol/coordinate";
import type { PropsOfType } from "utils/types";


export function numberAverage(values: number[]): number;
export function numberAverage(values: (number | undefined)[]): number | undefined;
export function numberAverage(values: (number | undefined)[]) {
  let n = 0;
  const sum = values.reduce<number>((acc, v) => {
    if (!num.valid(v)) return acc;
    n += 1;
    return v + acc;
  }, 0);
  return n ? sum / n : undefined;
}


type NumKeys<O, T extends number | undefined> = AnyKey & PropsOfType<O, T>;
function propAverage<T extends AnyRec, N extends number>(data: T[], prop: NumKeys<T, N>): N;
function propAverage<T extends AnyRec, N extends number | undefined>(data: T[], prop: NumKeys<T, N>): N;
function propAverage(data: AnyRec[], prop: AnyKey) {
  return mapAverage(data, obj => obj[prop]);
}

export function mapAverage<T>(objects: T[], fn: (obj: T) => number): number;
export function mapAverage<T>(objects: T[], fn: (obj: T) => number | undefined): number | undefined;
export function mapAverage<T>(objects: T[], fn: (obj: T) => number | undefined) {
  return numberAverage(objects.map(fn));
}

export function coordinateAverage<C extends Coordinate | vec2 | undefined>(coords: C[]): C;
export function coordinateAverage(coords: (Coordinate | vec2 | undefined)[]) {
  const coords_nn = arr.notnull(coords);
  const coord = [
    propAverage(coords_nn, 0),
    propAverage(coords_nn, 1),
  ];
  return coordinateValid(coord) ? coord : undefined;
}

export function intervalAverage(coords: NumInterval[]) {
  return {
    min: propAverage(coords, "min"),
    max: propAverage(coords, "max"),
  };
}

function vectorAverage<
  O, M extends NumKeys<O, N>, D extends NumKeys<O, N>,
  N extends number | undefined = number | undefined
>(data: O[], magKey: M, dirKey: D, radians = false) {
  let n = 0;
  const t = data.reduce((t, c) => {
    const mag = c[magKey];
    const dir = c[dirKey];
    if (num.valid(mag) && num.valid(dir)) {
      n++;
      const rad = radians ? dir : degToRad(dir);
      return [t[0] + Math.cos(rad) * mag, t[1] + Math.sin(rad) * mag];
    }
    return t;
  }, [0, 0]);

  const angle = angle0To2PI(getAngle([0, 0], t));

  return (n ? {
    [magKey]: getDistance(t) / n,
    [dirKey]: radians ? angle : radToDeg(angle)
  } : {
    [magKey]: undefined,
    [dirKey]: undefined
  }) as { [K in (M | D)]: O[K] };
}

function windAverage(data: { direction?: number, speed?: number; }[]) {
  return vectorAverage(data, "direction", "speed");
}



type PropMap<T extends AnyRec> = { [K in keyof Partial<T>]: (o: T[K][]) => T[K] };
export function objectAverage<T extends AnyRec>(objects: T[], mapping: PropMap<T>): T;
export function objectAverage(objects: AnyRec[], mapping: PropMap<AnyRec>): AnyRec {
  return obj.map(mapping, (v, k) => v(objects.map(o => o[k])));
}


export const avg = {
  number: numberAverage,
  prop: propAverage,
  map: mapAverage,
  obj: objectAverage,
  vector: vectorAverage,
  coord: coordinateAverage,
  interval: intervalAverage,
  wind: windAverage
}

