import { lazyWithPreload } from "react-lazy-with-preload";

export const LightTheme = lazyWithPreload(() => import(/* webpackChunkName: "LightTheme" */ "./light"));
export const DarkTheme = lazyWithPreload(() => import(/* webpackChunkName: "DarkTheme" */ "./dark"));

export function preloadTheme() {
  getUserTheme() === "dark" ? DarkTheme.preload() : LightTheme.preload();
}

export function getUserTheme(theme?: "dark" | "light") {
  if (!theme) {
    try {
      const stored = localStorage.getItem("theme:type");
      if (stored) theme = JSON.parse(stored);
    } catch { }
  }
  return theme === "dark" ? "dark" : "light";
}
