import React, { memo, Suspense } from 'react';
//import { lazyWithPreload } from "react-lazy-with-preload";
import { LoadingOverlay } from 'ui/components/FullOverlays';
import { preloadTheme } from 'ui/theme/theme.preload';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "ui/App"));
const StoreProvider = React.lazy(() => import(/* webpackChunkName: "Store" */ "store/utils/provider"));

export const AppContext = memo(() => {
  return (
    <Suspense fallback={<LoadingOverlay message="Loading UI" />}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </Suspense>
  );
});

//App.preload();
preloadTheme();
