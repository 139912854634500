import type { Opt } from "utils/types";
import { num } from "./number";

/**
 * Get element in an array with a minimum custom value
 * 
 * @param arr the array
 * @param f function that takes an element and returns a number
 * @param min optional, only returns the element with a value less than this
 * @returns the element with the minimum value or null
 */
export function min<T>(arr: T[], f: (el: T) => number | undefined, min?: number): { element: T | undefined, index: number, value: number } {
  let value = min ?? Number.MAX_VALUE;
  let element = undefined;
  let index = -1;
  arr.forEach((e, i) => {
    const d = f(e);
    if (d != null && d < value) {
      value = d;
      element = e;
      index = i;
    }
  });
  return { element, index, value };
}

/**
 * Get element in an array with a maximum custom value
 *
 * @param arr the array
 * @param f function that takes an element and returns a number
 * @param max optional, only returns the element with a value less than this
 * @returns the element with the maximum value or null
 */
export function max<T>(arr: T[], f: (el: T) => number, max?: number): { element: T | undefined, index: number, value: number } {
  let value = max ?? Number.NEGATIVE_INFINITY;
  let element = undefined;
  const index = -1;
  arr.forEach(e => {
    const d = f(e);
    if (d != null && d > value) {
      value = d;
      element = e;
    }
  });
  return { element, index, value };
}

export function getValueRange<T>(arr: T[], fn: (o: T) => any): NumInterval {
  let min = Number.POSITIVE_INFINITY;
  let max = Number.NEGATIVE_INFINITY;
  arr.forEach(o => {
    const value = fn(o);
    if (num.valid(value)) {
      if (value < min) min = value;
      if (value > max) max = value;
    }
  });
  return { min, max };
}

//
export function extremes<T>(arr: T[], fn: (o: T) => NumInterval | undefined): NumInterval {
  let min = Number.POSITIVE_INFINITY;
  let max = Number.NEGATIVE_INFINITY;
  arr.forEach(o => {
    const range = fn(o);
    if (num.valid(range?.min)) min = Math.min(min, range!.min);
    if (num.valid(range?.max)) max = Math.max(max, range!.max);
  });
  return { min, max };
}

export function notnull<T>(arr: (T | null | undefined)[]) {
  return arr.filter((t): t is Exclude<T, null | undefined> => t != null);
}

export function filter<I, O extends I>(arr: I[], condition: (i: I) => i is O): O[];
export function filter(arr: any[], condition: (i: any) => boolean): any[] {
  return arr.filter(condition);
}

export function range(len: number):number[]
export function range(min: number, max: number): number[];
export function range(min: number, max?: number) {
  if (max == null) {
    max = min;
    min = 0;
  }
  const range = max - min;
  const sign = Math.sign(range);
  min += Math.min(0, sign);
  return Array.from(Array(range * sign), (_, i) => min + i * sign);
}

export function filled<T>(size: number, value: T) : T[] {
  return Array(size).fill(value);
}

export function sortByAsc<T>(array:T[], getv: (item: T) => number | undefined) {
  return array.concat().sort((a, b) => {
    return (getv(a) ?? Number.POSITIVE_INFINITY) - (getv(b) ?? Number.POSITIVE_INFINITY);
  });
}

export function sortByDesc<T>(array: T[], getv: (item: T) => number | undefined) {
  return array.concat().sort((a, b) => {
    return (getv(b) ?? Number.NEGATIVE_INFINITY) - (getv(a) ?? Number.NEGATIVE_INFINITY);
  });
}

export function last<T>(a: T[]): T | undefined {
  return a[a.length - 1];
}


/** Returns nothing if not within limit_ms */
export function binarySearchNearest<T>(
  items: T[],
  value: number,
  fn: (o: T) => number,
  low?: number,
  high?: number
) {

  const l = items.length;
  let i = l === 1 ? 0 : Math.min(l - 1, binarySearchIndex(items, value, fn, true, low, high));
  let o: T = items[i];
  let dv = value - fn(o)
  // Check if prev is closer
  if (i > 0) {
    const o2 = items[i - 1];
    const dv2 = value - fn(o2);
    if (Math.abs(dv2) < Math.abs(dv)) {
      dv = dv2;
      o = o2;
      i = i - 1;
    }
  }
  return { index: i, delta: dv, value: o };
}

/** Binary search for first data point after timestamp, returns 0 -> length */
export function binarySearchIndex<T>(
  items: T[],
  value: number,
  fn: (o: T) => number,
  inclusive = true,
  low?: number,
  high?: number
): number {

  low = low ?? 0;
  high = high ?? items.length - 1;

  let o: T;
  let i = 0, dv = 0;
  while (low <= high) {
    i = (low + ((high - low) >> 1));
    o = items[i];
    dv = value - fn(o);
    if (dv === 0 && inclusive) {
      return i;
    }
    if (dv < 0) {
      high = i - 1;
    }
    else {
      low = i + 1;
    }
  }
  return low;
}

export function notEmpty<T extends Exclude<{}, undefined>>(array: T[]): array is [T, ...T[]];
export function notEmpty(array: any[]){
  return array.length !== 0 && array[0] !== undefined;
}

const arr = { min, max, last, extremes, range, filled, notnull, getValueRange, sortByAsc, sortByDesc, binarySearchNearest, binarySearchIndex, notEmpty };
export default arr;
