import { merge } from "highcharts";
import { error } from "utils/error";
import { setGlobal } from "utils/storage/global";
import { IApiConfigurations, IConfigFile } from "./schema";

class Config implements IConfig {
  constructor() {
    if (this.isLocal && this.isDev) {
      this.log.enabled = true;
      this.log.level = "debug";
    }
    setGlobal("config", this);
  }

  public async load() {
    let config = await loadConfig("config");
    if (this.isLocal) config = await loadConfig("config.local", config);
    Object.assign(this, config);
  }

  public loginUri!: string;
  public logoutUri!: string;
  public api!: IApiConfigurations;
  public devApp = window.location.hash === "#wind";
  public readonly log = {
    enabled: false,
    level: "warn",
    types: {
      mem: false,
    },
  };

  public async logout() {
    try {
      const res = await fetch(this.logoutUri);
      console.log(await res.text());
      location.reload();
    } catch (e) {}
  }

  public get isLocal() {
    return (
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
    );
  }
  public get isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }
}

export interface IConfig extends IConfigFile {
  load(): Promise<void>;
  logout(): Promise<void>;
  isDev: boolean;
  isLocal: boolean;
  devApp: boolean;
  log: {
    enabled: boolean;
    level: string;
    types: Record<string, boolean | undefined>;
  };
}

async function loadConfig(file: string, base?: IConfigFile) {
  try {
    const resp = await fetch(`config/${file}.json?rnd=${Math.random()}`);
    const json = await resp.json();
    if (base) {
      return merge(base, IConfigFile.deepPartial().parse(json));
    } else {
      return IConfigFile.parse(json);
    }
  } catch (cause) {
    throw error("Config", "failed to parse config file", { cause });
  }
}

export const config: IConfig = (globalThis.__app.config = new Config());
export default config;
