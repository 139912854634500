import 'moment/locale/en-gb';
import _moment, { isMoment } from "moment";
import { now as mobxNow } from "mobx-utils";
_moment.locale('en-gb');
import { tz } from 'moment-timezone';
import {
  HumanizeDurationLanguage,
  HumanizeDuration,
  HumanizeDurationOptions
} from 'humanize-duration-ts';

tz.setDefault('Etc/UTC');
import "moment-duration-format";
import { num } from './number';

export const moment = _moment;
export type Moment = _moment.Moment;
export type Duration = _moment.Duration;

const langService = new HumanizeDurationLanguage();
const humanizer = new HumanizeDuration(langService);
humanizer.setOptions({
  language: "en",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
      decimal: "."
    },
  },
  units: ["d", "h", "m", "s"],
  spacer: " ",
  largest: 3,
  round: true
});


const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const week = 7 * day;
const month = 30 * day; // approximite
const year = 365 * day;
const dateFmt = "YYYY-MM-DD HH:mm:ss";
const DATA_TIME_MIN = _moment().year(1995).valueOf();

const unit = {
  m: minute,
  s: second,
  h: hour,
  d: day,
  w: week,
  M: month,
  y: year
};

function round(time: number | Moment, unit: number) {
  return Math.round(time.valueOf() / unit) * unit;
}
function ceil(time: number | Moment, unit: number) {
  return Math.ceil(time.valueOf() / unit) * unit;
}
function floor(time: number | Moment, unit: number) {
  return Math.floor(time.valueOf() / unit) * unit;
}
function now(interval?: number | "frame") {
  return !interval ? new Date().getTime() : mobxNow(interval);
}

function getValidDataRange() {
  return { min: DATA_TIME_MIN, max: ceil(now(minute / 2), minute) };
};

function isValidDataRange(range?: PMNumInterval): range is MNumInterval {
  if (!range) return false;
  const { min, max } = range;
  if (!isValidDataTime(min) || !isValidDataTime(max)) return false;
  if ((max - min) <= 0) return false;
  const validRange = getValidDataRange();
  if (min < validRange.min || max > validRange.max) return false;
  return true;
}

function isValidDataTime(timeStamp?: number): timeStamp is number {
  if (!num.valid(timeStamp)) return false;
  const { min, max } = getValidDataRange();
  return min <= timeStamp && timeStamp <= max;
}

function asMoment(val: number | _moment.Moment) {
  return isMoment(val) ? val : _moment(val);
}

function format(timestamp: number | _moment.Moment) {
  return asMoment(timestamp).format(dateFmt);
}
format.date = format;
format.duration = function (milliseconds: number, opts?: HumanizeDurationOptions) {
  return humanizer.humanize(milliseconds, opts);
}
format.urlParam = function (timeStamp: number) {
  let format = dateFmt;
  const time = _moment(timeStamp);
  if (time.clone().startOf("day").valueOf() === timeStamp) {
    format = "YYYY-MM-DD";
  }
  return _moment(timeStamp).format(format).replace(" ", "+");
}


// All parse outputs should be unix timestamps in MILLIseconds
function parse(value: any) {
  if (isMoment(value)) {
    return value.valueOf();
  }
  if (typeof value === "number") {
    return parse.fromNumber(value)
  }
  if (typeof value === "string") {
    return parse.fromString(value);
  }
  return _moment(value).valueOf();
}
parse.fromNumber = function (value: number) {
  return value < DATA_TIME_MIN ? value * 1000 : value;
}
parse.fromString = function (value: string) {
  return _moment(value).valueOf();
}
parse.duration = function (value: any) {
  return _moment.duration(value).asMilliseconds();
};
parse.toUnix = function (value: any) {
  return parse(value) * 0.001;
}
parse.moment = _moment

export function duration(value: _moment.DurationInputArg1, unit?: _moment.unitOfTime.DurationConstructor | undefined) {
  return _moment.duration(value, unit);
}

function getUtcOffsetMs(timezone: string, atTime = now()) {
  const tz = _moment.tz.zone(timezone);
  if (!tz) {
    console.warn(`Timezone ${timezone} not found`);
    return 0;
  }
  return tz.utcOffset(atTime) * minute;
}

const localUtcOffset = new Date().getTimezoneOffset() * minute;
const serverUtcOffset = getUtcOffsetMs("Europe/Amsterdam");


export const time = Object.freeze({
  now,
  round,
  floor,
  ceil,

  second,
  minute,
  hour,
  day,
  week,
  year,
  unit,

  format,
  parse,
  duration,
  moment,
  isMoment,

  localUtcOffset,
  serverUtcOffset,
  getUtcOffsetMs,
  isValidDataRange,
  isValidDataTime,
  getValidDataRange,

});
