import { radToDeg } from "./geo";
import type { Coordinate } from "ol/coordinate";

export class Vector3d {

  constructor(public x: number, public y: number, public z: number) { }

  plus(v: Vector3d) {
    return new Vector3d(this.x + v.x, this.y + v.y, this.z + v.z);
  }

  minus(v: Vector3d) {
    return new Vector3d(this.x - v.x, this.y - v.y, this.z - v.z);
  }

  times(x: number) {
    return new Vector3d(this.x * x, this.y * x, this.z * x);
  }

  dividedBy(x: number) {
    return new Vector3d(this.x / x, this.y / x, this.z / x);
  }

  dot(v: Vector3d) {
    return this.x * v.x + this.y * v.y + this.z * v.z;
  }

  cross(v: Vector3d) {
    const x = this.y * v.z - this.z * v.y;
    const y = this.z * v.x - this.x * v.z;
    const z = this.x * v.y - this.y * v.x;
    return new Vector3d(x, y, z);
  }

  toCoord(): Coordinate {
    const φ = Math.atan2(this.z, Math.sqrt(this.x * this.x + this.y * this.y));
    const λ = Math.atan2(this.y, this.x);
    return [radToDeg(λ), radToDeg(φ)];
  }

}