import * as olExtent from 'ol/extent';
import { SimpleGeometry } from 'ol/geom';
import * as proj from 'ol/proj';
import type { vec2 } from "gl-matrix";
//
import { num } from './number';
//
import type { Coordinate } from 'ol/coordinate';
import type { Extent } from 'ol/extent';
import type { Opt } from 'utils/types';


// ----------------------------------------------------------------------------------
// Coordinates


// Effecient as possible geographic <-> Mercatore conversions
export function toMercator<C extends number[]>([lon, lat]: CoordLike<C>) {
  return [
    lon * EPSG3857_DEG,
    Math.log(Math.tan(DEGtoTAU * (lat + 90))) * EPSG3857_R
  ] as C;
}
export function toLonlat<C extends number[]>([lon, lat]: CoordLike<C>): C {
  return [
    lon * EPSG3857_DEG_inv,
    Math.atan(Math.exp(lat / EPSG3857_R)) * TAUtoDEG - 90
  ] as C;
}

// with optional/nullable params
export function toMercatorOpt<C extends Opt<number[]>>(coord: CoordLike<C>): C {
  return coord && toMercator(coord as number[]) as C;
}
export function toLonlatOpt<C extends Opt<number[]>>(coord: CoordLike<C>): C {
  return coord && toLonlat(coord as number[]) as C;
}


// Coord equality
export function coordinateEqual(a?: Coordinate, b?: Coordinate) {
  return a ? (b ? a[0] === b[0] && a[1] === b[1] : false) : !b;
}

// Coordinate validation
export function coordinateValid<T extends number[]>(coord?: (number | undefined)[]): coord is T {
  return validNumArray(coord, 2) && !(coord[0] === 0 && coord[1] === 0);
}

export function normalizeMerc<C extends Opt<number[]>>(coord: CoordLike<C>) : C {
  return coord && [
    num.mod(coord[0] + wmRange, wmRange * 2) - wmRange,
    coord[1]
  ] as C;
}

export function normalizeDeg(coord: Coordinate) {
  return [
    num.mod(coord[0] + 180, 360) - 180,
    coord[1]
  ];
}

// ----------------------------------------------------------------------------------
// Extents

// Extent transforms not bothering with strict non-nullalble versions
export function extentToMerator<Ext extends Opt<number[]>>(extent: ExtentLike<Ext>): Ext {
  return extent && [
    extent[0] * EPSG3857_DEG, latToMerc(extent[1]),
    extent[2] * EPSG3857_DEG, latToMerc(extent[3])
  ] as Ext;
};

export function extentToLonlat<Ext extends Opt<number[]>>(extent: ExtentLike<Ext>): Ext {
  return extent && [
    extent[0] * EPSG3857_DEG_inv, latToDeg(extent[1]),
    extent[2] * EPSG3857_DEG_inv, latToDeg(extent[3])
  ] as Ext;
};

export function transformExtent(coords: Extent, from: string, to: string): Extent {
  return proj.transformExtent(coords, from, to);
}

// bounding for a list of extents (not in ol for some reason)
export function totalExtent(extents: Extent[]) {
  if (!extents.length) return undefined;
  return extents.reduce((t, e) => t ? (extentValid(e) ? olExtent.extend(t, e) : t) : e);
}

// strict check if extent is valid
export function extentValid(coord: Partial<Extent> | undefined): coord is Extent {
  return validNumArray(coord, 4) && !olExtent.isEmpty(coord);
}

// generate valid extent or return nothing
export function validExtent(extent?: Extent | SimpleGeometry) {
  if (extent instanceof SimpleGeometry) extent = extent.getExtent();

  if (validNumArray(extent, 4) && !olExtent.isEmpty(extent)) {
    return olExtent.clone(extent);
  }
  return undefined;
}

// create a rectangle coords from an extent
export function extentCoords([xmin, ymin, xmax, ymax]: Extent) {
  return [
    [xmin, ymin],
    [xmin, ymax],
    [xmax, ymax],
    [xmax, ymin],
    [xmin, ymin]
  ] as Coordinate[];
}


// ----------------------------------------------------------------------------------
// Mercator zoom/resolution stuff.

export function getZoomFromResolution(resolution: number) {
  return Math.log((40075008 / 256) / resolution) / Math.log(2);
}

export function getResolutionFromZoom(zoom: number) {
  return (40075008 / 256) / (Math.pow(2, zoom));
}

// meters per pixel
export function getPointResolution(resolution: number, [,lat]: Coordinate) {
  return resolution * Math.cos(Math.abs(latToDeg(lat) * DEGtoRAD));
}



// ----------------------------------------------------------------------------------
// Basic unit conversions

export function metersToNM(meters: number) {
  return meters / 1852;
}

export function NMToMeters(NM: number) {
  return NM * 1852;
}

export function angle0To360(deg: number) {
  deg = deg % 360.0;
  return deg < 0.0 ? deg + 360.0 : deg;
}

export function angle180To180(deg: number) {
  deg = angle0To360(deg);
  return deg >= 180.0 ? deg - 360.0 : deg;
}

export function angle0To2PI(rad: number) {
  const n = rad / (Math.PI * 2);
  return (n - Math.floor(n)) * Math.PI * 2;
}

export function anglePIToPI(rad: number) {
  rad = angle0To2PI(rad);
  return rad >= Math.PI ? rad - Math.PI * 2 : rad;
}

export function degToRad(deg: number) {
  return deg * DEGtoRAD;
}

export function radToDeg(rad: number) {
  return rad * RADtoDEG;
}


// ----------------------------------------------------------------------------------
// Basic 2d geometric helpers

export function getDistance(coord1: Coordinate, coord2: Coordinate = [0, 0]) {
  const dx = coord1[0] - coord2[0];
  const dy = coord1[1] - coord2[1];
  return Math.sqrt(dx * dx + dy * dy);
}

export function getAngle(coord1: Coordinate, coord2: Coordinate) {
  return Math.atan2(coord2[1] - coord1[1], coord2[0] - coord1[0]);
}

export function toUV(value: number, angle: number) : Coordinate {
  const a = angle * DEGtoRAD;
  return [Math.cos(a) * value, Math.sin(a) * value];
}
export function fromUV(U: number, V: number) {
  return [Math.atan2(U, V), Math.sqrt(U ** 2 + V ** 2)] as [Radians, Magnitude];
}


// Constants ---------------------------------------------------------------------------

export const DEGtoRAD = Math.PI / 180;
export const DEGtoTAU = Math.PI / 360;
export const RADtoDEG = 180 / Math.PI;
export const TAUtoDEG = 360 / Math.PI;

export const EPSG3857_R = 6378137;
export const EPSG3857_HS = EPSG3857_R * Math.PI;
export const EPSG3857_DEG = EPSG3857_HS / 180;
export const EPSG3857_DEG_inv = 180 / EPSG3857_HS;
export const earthRadius = 6371e3 / 1852;
export const wmRange = 20037508.342789244;
export const wmExtent = Object.freeze([-wmRange, -wmRange, wmRange, wmRange]) as Extent;

export function wtoMercator<C extends NumArr>([lon, lat]: CoordLike<C>) {
  return [
    lon * EPSG3857_DEG,
    Math.log(Math.tan(DEGtoTAU * (lat + 90))) * EPSG3857_R
  ] as C;
}

export function wtoLonlat<C extends number[]>([lon, lat]: CoordLike<C>): C {
  return [
    lon * EPSG3857_DEG_inv,
    Math.atan(Math.exp(lat / EPSG3857_R)) * TAUtoDEG - 90
  ] as C & C[readonlyt];
}


// Utils --------------------------------------------------------------------------------

// lat conversions
const latToMerc = (lat: number) => Math.log(Math.tan(DEGtoTAU * (lat + 90))) * EPSG3857_R;
const latToDeg = (lat: number) => Math.atan(Math.exp(lat / EPSG3857_R)) * TAUtoDEG - 90;

// Verify array has valid numbers, with type gaurd
function validNumArray(arr: unknown, len: number): arr is number[] {
  return Array.isArray(arr)
    && arr.length === len
    && arr.every(num.valid);
}


type NumArr = number[] | ReadonlyArray<number>
type CoordLike<C extends Opt<NumArr>> = Exclude<C, [] | [number | never]> | vec2;
type ExtentLike<C extends Opt<number[]>> = Exclude<C, [] | [number | never] | [number | never, number | never] | [number | never, number | never, number | never]>;


type readonlyt = Exclude<keyof unknown[], keyof readonly unknown[]>

// Type tests -------------------------------------------------------------------------
// ignore

//type CoordTypes = [
//  [false, any],
//  [false, unknown],
//  [false, undefined],
//  [false, null],
//  [false, []],
//  [false, [number]],
//  [false, "boobies"],
//  [true, [number, number]],
//  [true, ICoordinate],
//  [true, Coordinate],
//  [true, [number, number, number]],
//  [true, [number, number, number, number]],
//  [true, Extent],
//  [true, IBounds],
//];

//type ArgsTest<L extends [boolean, any][], Fn> = {
//  [I in keyof L]: Fn extends (args: L[I][1]) => L[I][1] ? L[I][1] : "FAIL"
//};
//type TestResults = ArgsTest<CoordTypes, typeof extentToLonlat>;



// deprecated, remove -------------------------------------------------------------------------

/** @deprecated */
//export function toLonlat<T extends Coordinate | Extent | undefined>(coords: T): T {
//  if (!coords) return coords;
//  if (coords.length === 2)
//    return transformCoordinates(coords as Coordinate, "EPSG:3857", "EPSG:4326") as T;
//  else if (coords.length === 4)
//    return proj.transformExtent(coords as Extent, "EPSG:3857", "EPSG:4326") as T;
//  else
//    throw new Error();
//}

/** @deprecated */
//export function degToMet<T extends Coordinate | Extent | undefined>(coords: T): T {
//  if (!coords) return coords;
//  if (coords.length === 2)
//    return transformCoordinates(coords as Coordinate, "EPSG:4326", "EPSG:3857") as T;
//  else if (coords.length === 4)
//    return proj.transformExtent(coords as Extent, "EPSG:4326", "EPSG:3857") as T;
//  else
//    throw new Error();
//}

/** @deprecated */ // kind of, just use `ol.proj.transform`
//export function transformCoordinates(coords: Coordinate, from: string, to: string): Coordinate {
//  if (from === "EPSG:3857" && to === "EPSG:4326") {
//    return toLonlat(coords);
//  } else if (to === "EPSG:3857" && from === "EPSG:4326") {
//    return toMercator(coords);
//  } else {
//    return proj.transform(coords, from, to);
//  }
//}


