declare global {
  // eslint-disable-next-line no-var
  var __app: Record<string, any>;
}
export function useGlobalRecord(key: string, persist = false): Record<string, any> {
  const g = globalThis as any;
  if (persist) {
    return g[key] ?? (g[key] = {});
  } else {
    return g[key] = {};
  }
}

const __app = useGlobalRecord("__app", true);

export function setGlobal<T>(key: string, value: T): T {
  return __app[key] = value;
}
export function getGlobal<T = any>(key: string): T | undefined {
  return __app[key];
}
export function useGlobal<T>(key: string, value: () => T): T {
  return __app[key] ?? (__app[key] = value());
}
export function deleteGlobal(key: string) {
  delete __app[key];
}
