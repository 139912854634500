import { isNumber } from "mathjs";
import { num } from "./number";

export function toFraction(value: number, interval: NumInterval) {
  return (value - interval.min) / (interval.max - interval.min);
}

export function fromFraction(fraction: number, interval: NumInterval) {
  return fraction * (interval.max - interval.min) + interval.min;
}


function toTuple(interval: undefined): undefined;
function toTuple(interval: NumInterval): NumPair;
function toTuple(interval?: NumInterval): NumPair | undefined {
  return interval ? [interval.min, interval.max] : undefined;
}
function fromTuple(tuple: undefined): undefined;
function fromTuple(tuple: NumPair): NumInterval;
function fromTuple(tuple?: NumPair): NumInterval | undefined {
  return tuple ? { min: tuple[0], max: tuple[1] } : undefined;
}


export const intv = {

  equal(i1?: PMNumInterval, i2?: PMNumInterval, tollerance = 0): boolean {
    if (!i1) return !i2;
    if (!i2) return !i1;
    return Math.abs((i1.min ?? NaN) - (i2.min ?? NaN)) <= tollerance
        && Math.abs((i1.max ?? NaN) - (i2.max ?? NaN)) <= tollerance;
  },

  add(i1: NumInterval, i2: NumInterval | number) {
    if (isNumber(i2)) i2 = { min: i2, max: i2 };
    return { min: i1.min + i2.min, max: i1.max + i2.max };
  },

  subtract(i1: NumInterval, i2: NumInterval | number) {
    if (isNumber(i2)) i2 = { min: i2, max: i2 };
    return { min: i1.min - i2.min, max: i1.max - i2.max };
  },

  multiply(i1: NumInterval, i2: NumInterval | number) {
    if (isNumber(i2)) i2 = { min: i2, max: i2 };
    return { min: i1.min * i2.min, max: i1.max * i2.max };
  },

  valid(interval?: PMNumInterval | null, allowReverse = false): interval is NumInterval {
    return !!interval && num.valid(interval.min) && num.valid(interval.max) && (allowReverse || interval.min <= interval.max);
  },

  validOr<T>(interval: PMNumInterval | undefined | null, invalidValue: T, allowReverse = true): T | NumInterval {
    return intv.valid(interval, allowReverse) ? interval : invalidValue;
  },

  range(interval: NumInterval): number {
    return interval.max - interval.min;
  },

  rangeAbs(interval: NumInterval): number {
    return Math.abs(interval.max - interval.min);
  },

  pad(interval: NumInterval, padding: number): NumInterval {
    return { min: interval.min - padding, max: interval.max + padding };
  },

  clone(interval: NumInterval): NumInterval {
    return { ...interval };
  },

  scaleFromCenter(interval: NumInterval, factor: number): NumInterval {
    const pad = intv.range(interval) * (factor - 1) * 0.5;
    return { min: interval.min - pad, max: interval.max + pad }
  },

  inside(value: number, interval: NumInterval) {
    return value >= interval.min && value <= interval.max;
  },

  reversed(interval: MNumInterval) {
    return intv.range(interval) < 0;
  },

  reverse(interval: NumInterval) {
    return { min: interval.max, max: interval.min };
  },

  abs(interval: NumInterval) {
    return intv.reversed(interval) ? intv.reverse(interval) : interval;
  },

  min(a: NumInterval, b: NumInterval) {
    return { min: Math.max(a.min, b.min), max: Math.min(a.max, b.max) };
  },

  intersection(i1: MNumInterval, i2: NumInterval) {
    if (i1.max < i2.min) return undefined;
    if (i1.min > i2.max) return undefined;
    return {
      min: Math.max(i1.min, i2.min),
      max: Math.min(i1.max, i2.max)
    }
  },

  extend(i1: MNumInterval, i2: MNumInterval) {
    return {
      min: Math.min(i1.min, i2.min),
      max: Math.max(i1.max, i2.max)
    }
  },

  contains(l: MNumInterval, r: MNumInterval) {
    return l.min <= r.min && r.max <= l.max
  },


  toTuple,
  fromTuple,

  toFraction,
  fromFraction
};